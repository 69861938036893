<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfCanceled') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>       
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"      
                    v-model="search"     
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getCanceledList"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
        >   
            <CRow>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">SEALS</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seals"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">STATUS</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Status"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">CARRIER CODE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLineCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8  text-right">CARRIER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4  d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ShippingLine"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">IMDG CODE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ImdgCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">LOAD PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.LoadPort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DISCHARGE PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DischargePort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DELIVERY PORT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DeliveryPort"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl- 8 text-right">WEIGHT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Weigth"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">UN NUMBER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnNumber"
                            />
                        </div>
                    </div>
                </CCol>
                <!--CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">ARRIVAL POSITION</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ArrivalPosition"
                            />
                        </div>
                    </div>
                </CCol-->
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">MOV STOWAGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">TRANSAC STOWAGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.TransacStowageName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">MOV STOWAGE REASON</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center border-right">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageReasonName"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">REASON DESC</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.MovStowageReasonDs"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">YARD</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Yard"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">PROCEDENCE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Procedence"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="6" lg="6" xl="6">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIRECT DISCHARGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DirectDischarge"
                            />
                        </div>
                    </div>
                </CCol>
                
            </CRow>
            <div slot="footer">
                <CButton outline color="wipe" @click="modalColumn=false">
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                Status:true,
                LoadPort:true,
                DischargePort:true,
                DeliveryPort:true,
                Seals:true,
                Weigth:true,
                ShippingLineCode:true,
                ShippingLine:true,
                ImdgCode:true,
                UnNumber:true,
                //ArrivalPosition:true,
                MovStowageName:true,
                TransacStowageName:true,
                MovStowageReasonName:true,
                MovStowageReasonDs:true,
                ConfirmationDate:true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            search: '',
            pageSize: '',
            labelUnitMeasure: 'TON', 
            nulo:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;        

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            rowData.map(function(item){
                computedItems.push({
                    IdX: item.IdX,
                    BayCode: item.BayCode ? item.BayCode : '',
                    ContainerCode: item.ContainerCode,
                    Size: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    Status: item.TpCargoStatusName ? item.TpCargoStatusName : '',
                    Seals: item.Seals ? item.Seals : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                    Weigth: formatMilDecimal(Number.parseFloat(item.Weigth).toFixed(2)),
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLine: item.ShippingLine ? item.ShippingLine : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ArrivalPosition: item.ArrivalPosition ? item.ArrivalPosition : '',
                    MovStowageName: item.MovStowageName ? item.MovStowageName : '',
                    TransacStowageName: item.TransacStowageName ? item.TransacStowageName : '',
                    MovStowageReasonName: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                    MovStowageReasonDs: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect
                })
            })
            await this.getPdf(computedItems,this.$t('label.listOfPlannedCanceled'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }   
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.listOfPlannedCanceled'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false; 
    }

    function buscarUnidad(e) {
        this.unitMeasureId=e.target.value;
        this.getCanceledList();
    }

    async function getCanceledList () {
        this.loadingOverlay = true;

        let CargoSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };
        this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

        await this.$http.post("StowagePlanningCargoCancel-list", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            let listado = [...response.data.data];
            let i = 1;
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            
            this.rows =  listado.map((item) => {
                return {
                    ...item,
                    IdX: i++
                };
            });
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-edit text-white font-weight-bold'
        }
    }

    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                //lockVisible: true,
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                //pinned: 'left',
                cellClass: 'center-cell-especial gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "ArrivalPosition",
                headerName: "ARRIVAL POSITION",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                minWidth: 180,
                filter: "agTextColumnFilter",
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "ContainerCode",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                //pinned: 'left',
                cellClass: 'center-cell-especial gb-cell',
            },
            {
                field: "Size",
                headerName: "SIZE (FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                cellClass: 'center-cell-especial',
            },
            {
                field: "IsoCode",
                headerName: "ISO CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
                suppressMovable: true,
                lockPosition: true,
            }, 
        ]
        if(this.columnsOption.Seals){
            columnDefs.push({
                field: "Seals",
                headerName: "SEALS",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Status){
            columnDefs.push({
                field: "Status",
                headerName: "STATUS",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.ShippingLineCode){
            columnDefs.push({
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ShippingLine){
            columnDefs.push({
                field: "ShippingLine",
                headerName: "CARRIER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 120,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.ImdgCode){
            columnDefs.push({
                field: "ImdgCode",
                headerName: "IMDG CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.LoadPort){
            columnDefs.push({
                field: "LoadPort",
                headerName: "LOAD PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DischargePort){
            columnDefs.push({
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DeliveryPort){
            columnDefs.push({
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        /*if(this.columnsOption.ArrivalPosition){
            columnDefs.push({
                field: "ArrivalPosition",
                headerName: "ARRIVAL \nPOSITION",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }*/
        if(this.columnsOption.Weigth){
            columnDefs.push({
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 160,
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            });
        }
        if(this.columnsOption.UnNumber){
            columnDefs.push({
                field: "UnNumber",
                headerName: "UN NUMBER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.MovStowageName){
            columnDefs.push({
                field: "MovStowageName",
                headerName: "MOV \nSTOWAGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.TransacStowageName){
            columnDefs.push({
                field: "TransacStowageName",
                headerName: "TRANSAC \nSTOWAGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 220,
                filter: "agTextColumnFilter",
            });
        }
        
        if(this.columnsOption.MovStowageReasonName){
            columnDefs.push({
                field: "MovStowageReasonName",
                headerName: "MOV STOWAGE \nREASON",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 220,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.MovStowageReasonDs){
            columnDefs.push({
                field: "MovStowageReasonDs",
                headerName: "REASON \nDESC",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 180,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map((item)=>{
                computedItems.push({
                    IdX: item.IdX,
                    BayCode: item.BayCode ? item.BayCode : '',
                    ContainerCode: item.ContainerCode,
                    Size: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    Status: item.TpCargoStatusName ? item.TpCargoStatusName : '',
                    Seals: item.Seals ? item.Seals : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                    Weigth: item.Weigth,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLine: item.ShippingLine ? item.ShippingLine : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ArrivalPosition: item.ArrivalPosition ? item.ArrivalPosition : '',
                    MovStowageName: item.MovStowageName ? item.MovStowageName : '',
                    TransacStowageName: item.TransacStowageName ? item.TransacStowageName : '',
                    MovStowageReasonName: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                    MovStowageReasonDs: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        
        return computedItems;
    }

    export default {
        name: "canceled-list",
        data,
        beforeMount,
        mounted,
        mixins: [Reportes, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getCanceledList,
            getUnitMeasureWeigthList,
            buscarUnidad,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged
        },
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==22) {
                    this.pageSize='';
                    this.unitMeasureId= '';
                    this.search="";
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getCanceledList();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>